import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"

const Empleo = () => {
  const data = useStaticQuery(graphql`
    query Images_empleo {
      image: file(
        relativeDirectory: { eq: "empleo" }
        name: { eq: "icono-empleo" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image1: file(
        relativeDirectory: { eq: "empleo" }
        name: { eq: "cabecera-empleo" }
      ) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Trabaja con nosotros | Qualoom</title>
        <meta name="title" content="Trabaja con nosotros | Qualoom"/>
        <meta name="description" content="Ofrecemos un entorno de trabajo saludable, flexible, con desarrollo personal y profesional. Echa un vistazo a nuestras oportunidades."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/empleo/"/>
        <link rel="canonical" href="https://www.qualoom.es/empleo/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>
        
        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/empleo/"/>
        <meta property="og:title" content="Trabaja con nosotros | Qualoom"/>
        <meta property="og:description" content="Ofrecemos un entorno de trabajo saludable, flexible, con desarrollo personal y profesional. Echa un vistazo a nuestras oportunidades."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2RlgyCrAEUpdKRxj8UWjUG/f51308c39df9bfd9f8aeabe572a9cd62/tw_cards_empleo.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>
        <meta property="fb:admins" content="338114870907726"/>
        
        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content="https://www.qualoom.es/empleo/"/>
        <meta property="twitter:title" content="Trabaja con nosotros | Qualoom"/>
        <meta property="twitter:description" content="Ofrecemos un entorno de trabajo saludable, flexible, con desarrollo personal y profesional. Echa un vistazo a nuestras oportunidades."/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/2RlgyCrAEUpdKRxj8UWjUG/f51308c39df9bfd9f8aeabe572a9cd62/tw_cards_empleo.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

      </Helmet>
      <BackgroundImage fluid={data.image1.childImageSharp.fluid}>
        <div className="background-empleo">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>¿Te gustaría trabajar en nuestro equipo?</h1>
              <p>Si disfrutas con los grandes retos, deseas desarrollar tu carrera profesional en el sector de la tecnología/consultoría en un entorno innovador y en colaboración con equipos altamente cualificados, este es tu lugar. Ofrecemos un entorno de trabajo saludable, flexible, con desarrollo personal y profesional. ¡Echa un vistazo a nuestras oportunidades!</p>
            </div>
          </div>
        </div>
      </BackgroundImage>

      {/*Primera Oferta*/}
      <div className="main_left">
        <div className="empleo-image"><Img fluid={data.image.childImageSharp.fluid} className="data-image" alt="Primera Oferta" /></div>
        <div className="empleo-text">
          <h2>Perfil Junior Administración de sistemas Línux y BigData</h2>
          <p>Qualoom busca incorporar a su equipo perfiles con experiencia técnica en ecosistemas Linux y Big Data, tecnologías Spark, Hadoop (Cloudera) con ganas de aprender y capacidad de desarrollar su carrera en una empresa estable y puntura en nuevas tecnologías para importante cliente.</p>
          <Link to="/perfil-administracion-de-sistemas-linux-y-bigdata/">{" "}<button className="oportunidad">Ver oportunidad</button></Link>
        </div>
      </div>
      {/*Segunda Oferta*/}
      <div className="main_left">
        <div className="empleo-image"><Img fluid={data.image.childImageSharp.fluid} className="data-image" alt="Segunda Oferta"/></div>
        <div className="empleo-text">
          <h2>Diseñador de dispositivos electrónicos</h2>
          <p>Qualoom busca incorporar a su equipo un candidato con experiencia en el diseño, implementación y puesta a punto de sistemas electrónicos con hardware analógico/digital microcontrolado. Preferiblemente con residencia Móstoles o alrededores.</p>
          <Link to="/perfil-disenador-dispositivos-electronicos/">{" "}<button className="oportunidad">Ver oportunidad</button></Link>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Empleo
